.announcement-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background: linear-gradient(to right, #f953c6, #b91d73);
  box-shadow: 4px 4px 50px 0px #b3295f;
  color: white;
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 112;
}

.announcement-text {
  display: inline-block;
  padding-left: 20%;
  animation: marquee 10s linear infinite;
  font-size: 20px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
